import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/remote-config';

import { AppConfig } from 'config';

import { getWithExpiry, setWithExpiry } from 'utils/localStorage';

import { PaymentTypes } from 'constants/common';
import {
  DEFAULT_SUBSCRIPTION_TYPE,
  REMOTE_CONFIG_KEY,
} from 'constants/firebase';

export enum SubscriptionTypeModes {
  default = 'default',
  discount = 'discount',
}

export type SubscriptionType = string | Record<SubscriptionTypeModes, string>;

type HostToDataMap<T> = {
  'relationship.sensualcourse.com': T;
  'premium.sensualcourse.com': T;
  'app.sensualcourse.com': T;
  default: T;
};

interface IReview {
  name: string;
  date: string;
  avatar: string;
  text: string;
  caption?: string;
}

interface AfterPaywallData {
  title: string;
  subtitle: string;
  link: string;
  img: string;
  type: string;
  contentType: string;
  button: string;
  backButton?: boolean;
  badgeColor?: string;
  content: {
    header?: [
      {
        title: string;
        subTitle: string;
      },
    ];
    title: string;
    list: [
      {
        title: string;
        subTitle: string;
      },
    ];
    title2?: string;
    list2?: [
      {
        title: string;
        subTitle: string;
      },
    ];
    legalInformation?: {
      title: string;
    };
  };
}

interface ISubscriptionsConvertedResult {
  name: string;
  ttl: number;
  default_value: any;
  pages_v2: {
    page: string;
    domain: string;
    values: {
      default_paywall_value: any;
      paywall_type: string;
      product_values: {
        value: any;
        chance: number;
      }[];
    }[];
  }[];
}

interface IConvertedResult {
  name: string;
  ttl: number;
  default_value: any;
  pages: {
    page: string;
    domain?: string;
    default_page_value: any;
    values: {
      value: any;
      chance: number;
    }[];
  }[];
}

interface AfterPaywallDataCollection {
  [key: string]: AfterPaywallData;
}

interface ICustomWindow extends Window {
  config: { [key: string]: any };
}

declare let window: ICustomWindow;

class FirebaseServiceClass {
  page: string = '';
  domain: string = '';

  init() {
    if (typeof window !== 'object') {
      return;
    }
    const firebaseConfig = {
      apiKey: AppConfig.FIREBASE_API_KEY,
      authDomain: AppConfig.FIREBASE_AUTH_DOMAIN,
      projectId: AppConfig.FIREBASE_PROJECT_ID,
      storageBucket: AppConfig.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: AppConfig.FIREBASE_MESSAGING_SENDER_ID,
      appId: AppConfig.FIREBASE_APP_ID,
      measurementId: AppConfig.FIREBASE_MEASUREMENT_ID,
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app();
    }
    firebase.analytics();

    [this.domain] = window.location.hostname.split('.');
  }

  applyPage(pageName: string) {
    this.page = pageName.replace(/\//g, '');
  }

  getRowValue = <T>(key: REMOTE_CONFIG_KEY): T | null =>
    window.config[key] || undefined;

  getValues = (
    values: {
      value: string;
      chance: number;
    }[],
    lottery: number,
  ) =>
    values
      .reduce<{ value: unknown; chance: [number, number] }[]>(
        (previousValue, currentValue) => {
          const prevChance =
            previousValue.length > 0
              ? previousValue[previousValue.length - 1].chance[1]
              : 0;
          return [
            ...previousValue,
            {
              ...currentValue,
              chance: [prevChance, currentValue.chance + prevChance],
            },
          ];
        },
        [],
      )
      .filter(
        currentValue =>
          lottery >= currentValue.chance[0] && lottery < currentValue.chance[1],
      );

  subscriptionType = (paywallType: string): SubscriptionType => {
    const ttl = 604800000;
    const defaultValue = DEFAULT_SUBSCRIPTION_TYPE;
    const { page, domain } = this;
    const cacheKey = `${page}${page ? '/' : ''}${
      REMOTE_CONFIG_KEY.WEB_SUBSCRIPTION
    }`;
    const oldResult = getWithExpiry(cacheKey);
    if (oldResult) {
      return oldResult;
    }

    const result = this.getRowValue<ISubscriptionsConvertedResult>(
      REMOTE_CONFIG_KEY.WEB_SUBSCRIPTION,
    );

    if (result) {
      const remoteDefaultValues = result.default_value;

      const pageAndDomainValues = result.pages_v2
        .find(pageItem => pageItem.page === page && pageItem.domain === domain)
        ?.values.find(paywallItem => paywallItem.paywall_type === paywallType);
      const defaultPageAndDomainPaywallValue =
        pageAndDomainValues?.default_paywall_value;

      if (pageAndDomainValues?.product_values) {
        const value = this.getValues(
          pageAndDomainValues.product_values,
          this.lottery,
        );
        if (!value || !value?.length) {
          return (
            remoteDefaultValues ||
            defaultPageAndDomainPaywallValue ||
            defaultValue
          );
        }
        setWithExpiry(cacheKey, value[0].value, result.ttl || ttl);
        return value[0].value as SubscriptionType;
      }

      const pageValues = result.pages_v2
        .find(pageItem => pageItem.page === page)
        ?.values.find(paywallItem => paywallItem.paywall_type === paywallType);
      const defaultPaywallValue = pageValues?.default_paywall_value;

      if (pageValues?.product_values) {
        const value = this.getValues(pageValues.product_values, this.lottery);
        if (!value || !value?.length) {
          return remoteDefaultValues || defaultPaywallValue || defaultValue;
        }
        setWithExpiry(cacheKey, value[0].value, result.ttl || ttl);
        return value[0].value as SubscriptionType;
      } else {
        return remoteDefaultValues || defaultValue;
      }
    }
    return defaultValue;
  };

  getABItem = (key: REMOTE_CONFIG_KEY, ttl: number = 604800000) => {
    const { page, domain } = this;
    const cacheKey = `${page}${page ? '/' : ''}${key}`;
    const oldResult = getWithExpiry(cacheKey);
    if (oldResult) {
      return oldResult;
    }
    const result = this.getRowValue<IConvertedResult>(key);
    if (!result) {
      return null;
    }

    const remoteDefaultValue = result.default_value;

    const pageAndDomainValues = result.pages.find(
      pageItem => pageItem.page === page && pageItem.domain === domain,
    );
    if (pageAndDomainValues) {
      const value = this.getValues(pageAndDomainValues?.values, this.lottery);
      const defaultPageValue = pageAndDomainValues.default_page_value;

      if (!value || !value?.length) {
        const rootValues = result.pages.find(
          pageItem => pageItem.page === '',
        )?.values;
        if (rootValues) {
          return (
            this.getValues(rootValues, this.lottery)[0].value ||
            defaultPageValue ||
            remoteDefaultValue
          );
        }
        return remoteDefaultValue;
      }
      setWithExpiry(cacheKey, value[0].value, result.ttl || ttl);
      return value[0].value;
    }

    const pageValues = result.pages.find(pageItem => pageItem.page === page);
    if (pageValues) {
      const value = this.getValues(pageValues?.values, this.lottery);
      const defaultPageValue = pageValues.default_page_value;

      if (!value || !value?.length) {
        const rootValues = result.pages.find(
          pageItem => pageItem.page === '',
        )?.values;
        if (rootValues) {
          return (
            this.getValues(rootValues, this.lottery)[0].value ||
            defaultPageValue ||
            remoteDefaultValue
          );
        }
        return remoteDefaultValue;
      }
      setWithExpiry(cacheKey, value[0].value, result.ttl || ttl);
      return value[0].value;
    }
  };

  get lottery() {
    return Math.random() * 100 + 1;
  }

  get onboardingType() {
    const override = this.webConfigAbOverride;
    if (override) {
      if (override.web_onbording_type != null) {
        return override.web_onbording_type;
      }
    }
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_ONBOARDING_TYPE);
  }

  get paywallType() {
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_PAYWALL_TYPE);
  }

  get upgradeType() {
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_UPGRADE_TYPE);
  }

  get afterPaywallType() {
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_AFTER_PAYWALL_TYPE);
  }

  get successType() {
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_SUCCESS_TYPE);
  }

  get landingType() {
    const override = this.webConfigAbOverride;
    if (override) {
      if (override.web_landing_type != null) {
        return override.web_landing_type;
      }
    }
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_LANDING_TYPE);
  }

  get reviews(): IReview[] {
    return this.getABItem(REMOTE_CONFIG_KEY.REVIEWS) || [];
  }

  get paymentProviders(): PaymentTypes[] {
    return (
      this.getABItem(REMOTE_CONFIG_KEY.WEB_PAYMENT_PROVIDERS) || ['paypro']
    );
  }

  get pages(): string[] {
    const rawData = this.getRowValue<string[]>(REMOTE_CONFIG_KEY.WEB_PAGES) || [
      '',
    ];
    return rawData;
  }

  private selectDataByHost<T>(record: HostToDataMap<T>) {
    const host = window.location.host;
    if (record.hasOwnProperty(host)) {
      const data = record[host as keyof HostToDataMap<T>];
      return data ?? record.default;
    }
    return record.default;
  }

  get payproConfig() {
    const record: HostToDataMap<{
      templateId: string;
      accountIdField: string;
      deviceIdField: string;
      startUrlField?: string;
      urlParamsField?: string;
      deviceParamsField?: string;
    }> = this.getRowValue(REMOTE_CONFIG_KEY.WEB_PAYPRO_CONFIG)!;

    return this.selectDataByHost(record);
  }

  get productIds(): Record<string, string[]> {
    return this.getRowValue<Record<string, string[]>>(
      REMOTE_CONFIG_KEY.WEB_PRODUCT_IDS,
    )!;
  }

  get afterPaywallProductIds(): string[] {
    return this.getABItem(REMOTE_CONFIG_KEY.AFTER_PAYWALL_PRODUCT_ID);
  }

  afterPaywallProductContentData(id: string): undefined | AfterPaywallData {
    const data = this.getRowValue<AfterPaywallDataCollection>(
      REMOTE_CONFIG_KEY.AFTER_PAYWALL_PRODUCT_DATA,
    );
    return data?.[id];
  }

  get showAfterPaywall() {
    return this.getABItem(REMOTE_CONFIG_KEY.AFTER_PAYWALL);
  }

  get showDiscountPage() {
    return this.getABItem(REMOTE_CONFIG_KEY.SHOW_DISCOUNT_PAGE);
  }

  get showTariffDuplicate() {
    return this.getABItem(REMOTE_CONFIG_KEY.SHOW_TARIFF_DUPLICATE);
  }

  get selectedProductId() {
    return this.getRowValue<Record<string, string>>(
      REMOTE_CONFIG_KEY.WEB_SELECTED_PRODUCT_ID,
    );
  }

  get webConfigAbOverride() {
    return this.getABItem(REMOTE_CONFIG_KEY.WEB_CONFIG_AB_OVERRIDE);
  }

  get skipAuthPaywallTypes() {
    return this.getRowValue<string[]>(
      REMOTE_CONFIG_KEY.SKIP_AUTH_PAYWALL_TYPES,
    );
  }

  get marketingPrice() {
    return this.getRowValue<number>(REMOTE_CONFIG_KEY.MARKETING_PRICE);
  }

  get canSkipAuth() {
    return Boolean(this.skipAuthPaywallTypes?.includes(this.paywallType));
  }

  get upgradeSubscriptionIds(): { expensive: string; cheap: string } {
    return this.getABItem(REMOTE_CONFIG_KEY.UPGRADE_PAYWALL_SUBSCRIPTIONS_IDS);
  }

  get showUpgradePaywall(): boolean {
    return this.getABItem(REMOTE_CONFIG_KEY.UPGRADE_PAYWALL);
  }

  get hiddenProductIds(): Record<string, string[]> {
    return this.getRowValue(REMOTE_CONFIG_KEY.WEB_PRODUCT_HIDE_IDS)!;
  }

  get premiumUpgradeIds(): Record<string, string> {
    return this.getRowValue(REMOTE_CONFIG_KEY.WEB_PREMIUM_UPGRADE_IDS)!;
  }
}

export const FirebaseService = new FirebaseServiceClass();
