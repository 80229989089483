import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { lazy, useLayoutEffect, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useMst } from 'stores/RootStore';

import { Amplitude } from 'services/amplitude';
import { FirebaseService } from 'services/remoteConfig';

import { getAndSaveValue } from 'utils/localStorage';

import { useQueryParams } from 'hooks/useQueryParams';
import { useSubrouteName } from 'hooks/useSubrouteName';

import { ProtectedRoute, RedirectConditions } from 'navigations/ProtectedRoute';

import { DiscountPage } from 'pages/DiscountPage/DiscountPage';

import { APP_ROUTES } from './Routs';

const LandingPage = lazy(() => import('pages/LandingPage/LandingPage'));
const OnBoardingPage = lazy(() => import('pages/OnBoarding/OnBoarding'));
const PaywallPage = lazy(() => import('pages/PaywallPage/PaywallPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage/ProfilePage'));
const PersonaInfoPage = lazy(
  () => import('pages/PersonaInfoPage/PersonaInfoPage'),
);
const SuccessPage = lazy(() => import('pages/SuccessPage'));
const ConfirmPage = lazy(() => import('pages/ConfirmPage/ConfirmPage'));
const SubscriptionsPage = lazy(
  () => import('pages/SubscriptionPage/SubscriptionPage'),
);
const AfterPaywallPage = lazy(
  () => import('pages/AfterPaywallPage/AfterPaywallPage'),
);
const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const NoMatch = lazy(() => import('pages/404/404'));
const RedirectPage = lazy(() => import('pages/RedirectPage/RedirectPage'));
const MailingDisablePage = lazy(
  () => import('pages/MailingDisablePage/MailingDisablePage'),
);
const UpgradeSubPage = lazy(
  () => import('pages/UpgradeSubPage/UpgradeSubPage'),
);

const UserTimeData = {
  'Cohort Date': getAndSaveValue(
    'Cohort Date',
    DateTime.now().toFormat('yyyy/MM/dd'),
  ),
  'Cohort Day': getAndSaveValue('Cohort Day', DateTime.now().day),
  'Cohort Week': getAndSaveValue('Cohort Week', DateTime.now().weekNumber),
  'Cohort Month': getAndSaveValue('Cohort Month', DateTime.now().monthLong),
  'Cohort Year': getAndSaveValue('Cohort Year', DateTime.now().year),
};

export const NestedNavigator = observer(() => {
  const { paymentStore } = useMst();
  const { path } = useRouteMatch();
  const { setSubrouteName } = useSubrouteName();
  const basePath = path === '/' ? '' : path;
  const queryParams = useQueryParams();

  FirebaseService.applyPage(basePath);

  useLayoutEffect(() => {
    setSubrouteName(basePath);
    paymentStore.setPaymentType(FirebaseService.paymentProviders[0]);
    const fullRoute = FirebaseService.page
      ? `${window.location.pathname}/${window.location.search}`
      : `${window.location.pathname}${window.location.search}`;

    Amplitude.setUserProperties({
      ...UserTimeData,
      'Landing type': FirebaseService.landingType,
      'Onboarding type': FirebaseService.onboardingType,
      'Paywall type': FirebaseService.paywallType,
      'Success name': FirebaseService.successType,
      'Route name': FirebaseService.page,
      'Full route': fullRoute,
      Subdomain: window.location.hostname.split('.')?.[0] || 'default',
    });

    const startPage =
      queryParams.get('custom-fields[startPage][]') ||
      queryParams.get('custom-fields%5bstartPage%5d%5b%5d') ||
      '/';
    if (
      startPage !== '/' &&
      startPage !== FirebaseService.page &&
      FirebaseService.pages.includes(startPage)
    ) {
      FirebaseService.applyPage(startPage);
      setSubrouteName(startPage);
      paymentStore.setPaymentType(FirebaseService.paymentProviders[0]);
      window.location.pathname = `${startPage}${window.location.pathname}`;
    }
  }, []);

  const paywallRoute = useMemo(
    () =>
      FirebaseService.canSkipAuth ? (
        <Route path={`${basePath}${APP_ROUTES.PAY_WALL}`}>
          <PaywallPage />
        </Route>
      ) : (
        <ProtectedRoute
          basePath={basePath}
          redirectPath={APP_ROUTES.HOME}
          condition={RedirectConditions.TOKEN}
          path={`${basePath}${APP_ROUTES.PAY_WALL}`}>
          <PaywallPage />
        </ProtectedRoute>
      ),
    [basePath],
  );

  const discountRoute = useMemo(
    () =>
      FirebaseService.canSkipAuth ? (
        <Route path={`${basePath}${APP_ROUTES.DISCOUNT}`}>
          <DiscountPage />
        </Route>
      ) : (
        <ProtectedRoute
          basePath={basePath}
          redirectPath={APP_ROUTES.HOME}
          condition={RedirectConditions.TOKEN}
          path={`${basePath}${APP_ROUTES.DISCOUNT}`}>
          <DiscountPage />
        </ProtectedRoute>
      ),
    [basePath],
  );

  return (
    <Switch>
      <Route exact path={`${basePath}${APP_ROUTES.HOME}`}>
        <LandingPage />
      </Route>
      <Route path={`${basePath}${APP_ROUTES.ON_BOARDING}`}>
        <OnBoardingPage />
      </Route>
      {paywallRoute}
      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.LOGIN}
        condition={[RedirectConditions.CONFIRMED, RedirectConditions.TOKEN]}
        path={`${basePath}${APP_ROUTES.PROFILE}`}>
        <ProfilePage />
      </ProtectedRoute>
      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.LOGIN}
        condition={[RedirectConditions.CONFIRMED, RedirectConditions.TOKEN]}
        path={`${basePath}${APP_ROUTES.SUBSCRIPTIONS}`}>
        <SubscriptionsPage />
      </ProtectedRoute>
      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.LOGIN}
        condition={[RedirectConditions.CONFIRMED, RedirectConditions.TOKEN]}
        path={`${basePath}${APP_ROUTES.EXTRA}`}>
        <AfterPaywallPage />
      </ProtectedRoute>
      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.LOGIN}
        condition={[RedirectConditions.CONFIRMED, RedirectConditions.TOKEN]}
        path={`${basePath}${APP_ROUTES.SALE}`}>
        <UpgradeSubPage />
      </ProtectedRoute>
      {discountRoute}
      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.CONFIRM}
        condition={[RedirectConditions.CONFIRMED, RedirectConditions.TOKEN]}
        path={`${basePath}${APP_ROUTES.PERSONAL_INFO}`}>
        <PersonaInfoPage />
      </ProtectedRoute>
      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.CONFIRM}
        condition={[
          RedirectConditions.TOKEN,
          // RedirectConditions.PAYMENT_APPROVED,
          RedirectConditions.CONFIRMED,
        ]}
        path={`${basePath}${APP_ROUTES.SUCCESS}`}>
        <SuccessPage />
      </ProtectedRoute>

      <ProtectedRoute
        basePath={basePath}
        redirectPath={APP_ROUTES.HOME}
        condition={[
          RedirectConditions.TOKEN,
          // RedirectConditions.PAYMENT_APPROVED,
        ]}
        path={`${basePath}${APP_ROUTES.CONFIRM}`}>
        <ConfirmPage />
      </ProtectedRoute>

      <Route path={`${basePath}${APP_ROUTES.LOGIN}`}>
        <LoginPage />
      </Route>
      <Route path={APP_ROUTES.REDIRECT}>
        <RedirectPage />
      </Route>
      <Route path={APP_ROUTES.MAILING_DISABLE}>
        <MailingDisablePage />
      </Route>
      <Route path={APP_ROUTES.E404}>
        <NoMatch />
      </Route>
    </Switch>
  );
});
