import { ReactNode } from 'react';

export type WithData<T> = {
  data: T;
};

export type SensesServerReponse<T> = {
  result: T;
};

export type OnboardingResultDTO = {
  onBoardingId: string;
  result: OnBoardingResultItem[];
};

export type OnBoardingResultItem = {
  questionId: string;
  title: string;
  result: string[];
};

export type GetOnBoardingResponse = {
  _id: string;
  completed: boolean;
  steps: StepsProps[];
};

export interface IAuthVerifyOrChangeEmailResponse {
  result: boolean;
  jwtToken?: string;
  message?: string;
}

export enum EmailVerificationStatuses {
  NEW_USER = 1,
  EXISTING_USER = 2,
  ERROR = 3,
}

export enum AccountStatuses {
  PENDING = 'Pending',
  ACTIVE = 'Active',
}

export interface IAuthSignupResponse {
  result: {
    status: EmailVerificationStatuses;
    jwtToken?: string;
    accountStatus: AccountStatuses;
  };
}

export enum StepsTypes {
  map = 'map',
  experts = 'experts',
  singleChoice = 'singleChoice',
  multiChoice = 'multiChoice',
  email = 'email',
  reviews = 'reviews',
  loader = 'loader',
  infoCard = 'infoCard',
  rate = 'rate',
  info = 'info',
  chart = 'chart',
  featuresCards = 'features-cards',
  infoCentered = 'info-centered',
  relationsInfo = 'relations-info',
  questionPopup = 'question-popup',
  relationsReviews = 'relationship-reviews',
  agePiker = 'agePiker',
  prePaywall = 'prePaywall',
  watercup = 'watercup',
  recommendation = 'recommendation',
}

export type StepsProps = {
  id: number;
  _id: string;
  title?: string;
  type: StepsTypes;
  placeholder?: string;
  text?: string;
  imageUrl?: string;
  minValue?: number;
  maxValue?: number;
  variants: Variants[];
  defaultValue?: number;
};

export type Variants = {
  id: number;
  text: string;
  emoji: string;
};

export type Timeout = ReturnType<typeof setInterval>;

export enum GENDERS {
  MALE = 'male',
  FEMALE = 'female',
  ALL = 'all',
}

export interface IMenuItem {
  title: ReactNode;
  text?: ReactNode;
  onPress: () => void;
}

export interface IStripeCreateSubscriptionRequest {
  priceId: string;
  paymentMethodId: string;
  email: string;
  fbc?: string;
  fbp?: string;
}

export interface IStripeCreateSubscriptionResponse {
  data: {
    subscription: any;
  };
}

export interface IStripeRetryInvoiceRequest {
  invoiceId: string;
  paymentMethodId: string;
  email: string;
  fbc?: string;
  fbp?: string;
}

export type IPaypalSubscriptionCreateRequest = {
  planId: string;
  deviceId: string;
};

export interface IStripeGetPricesRequest {
  priceIds: string[];
}

export interface IProductRequest {
  productIds: string[];
  applicationId: string;
}

export interface ITrial {
  amount: number;
  currency: string;
  interval: DATE_INTERVAL;
  intervalCount: number;
}

export interface IUpgradeMatrix {
  payProProductId: string;
  upgradePrice: number;
  localProductId: string;
}
export interface IProductDescription {
  price_id: string;
  active: boolean;
  amount: number;
  currency: string;
  interval: DATE_INTERVAL;
  intervalCount: number;
  trial: ITrial;
  coupon?: string;
  upgradeMatrix?: IUpgradeMatrix[];
}

export interface IProduct {
  stripe: IProductDescription;
  paypal: IProductDescription;
  paypro: IProductDescription;
}

export interface IProductMap extends Partial<Record<string, IProduct>> {}

export interface IProductResponse {
  result: IProductMap;
}

export interface ICancelStripeSubscriptionRequest {
  priceId: string;
  cancelAtPeriodEnd: boolean;
  fbc?: string;
  fbp?: string;
}

export interface ICancelStripeSubscriptionResponse {
  success: boolean;
}

export type ICancelPaypalSubscriptionRequest = string;

export interface ICancelPaypalSubscriptionResponse {
  result: boolean;
}

export type ICancelPayproSubscriptionResponse =
  | {
      result: boolean;
    }
  | {
      message: string;
    };

export interface IConfirmPaypalSubscriptionResponse {
  result: boolean;
}

export interface ISubscription {
  applicationId: string;
  originalTransactionId: string;
  transactionId: string;
  cancellationTimestamp?: number;
  from: number;
  to: number;
  valid: boolean;
  gracePeriod: boolean;
  autoRenewing: boolean;
  productId: string;
  localProductId: string;
  trial: boolean;
  environment: 'Sandbox' | 'Production';
  userId: string;
  platform: string;
}

export interface ISubscriptionWithIntervalDate extends ISubscription {
  interval: string;
  intervalCount: number;
  amount: number;
  currency: string;
  priceId: string;
  title: string;
  subtitle: string;
}

export interface IGetAllSubscriptionsResponse {
  result: ISubscription[];
}

export enum WebPaymentStatus {
  CUSTOMER_NOT_EXISTS = 0,
  PREVIOUS_SUBSCRIPTION_NOT_CANCELLED = 1,
  PRODUCT_NOT_FOUND = 2,
  PRICE_NOT_FOUND = 3,
  CONFIG_NOT_FOUND = 4,
  PARAM_NOT_FOUND = 5,
  SUBSCRIPTION_NOT_FOUND = 6,
}

export enum PageTypes {
  ALPHA = 'alpha',
  BRAVO = 'bravo',
  BETTA = 'betta',
  CHARLIE = 'charlie',
  CHARLIE_ES = 'charlie_es',
  CHARLIE_EX = 'charlie_ex',
  DELTA = 'delta',
  DELTA_ES = 'delta_es',
  TEST = 'test',
  POPUP = 'popup',
  POPUP2 = 'popup2',
  POPUP2_TARIFF_DUPLICATE = 'popup2-tariff-duplicate',
  POPUP2_WOMAN = 'popup2woman',
  POPUP2_WOMAN_TARIFF_DUPLICATE = 'popup2woman-tariff-duplicate',
  POPUP2_ES = 'popup2es',
  POPUP2_WOMAN_ES = 'popup2womanes',
  RELATIONSHIP = 'relationship',
  RELATIONSHIP_ALPHA = 'relationship-aplha',
  MAN_AGE = 'man-age',
  WOMAN_AGE = 'woman-age',
}

export enum AfterPageTypes {
  ALPHA = 'alpha',
}

export enum DATE_INTERVAL {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const DAYS_IN_DATE_INTERVAL = {
  [DATE_INTERVAL.DAY]: 1,
  [DATE_INTERVAL.WEEK]: 7,
  [DATE_INTERVAL.MONTH]: 31,
  [DATE_INTERVAL.YEAR]: 365,
};

interface IPayproPushbackData {
  _id: string;
  applicationId: string;
  subscriptionId: string;
  userId: string;
  originalUserId: string;
  subscriptionStatus: string;
  localProductId: string;
  productId: string;
  orderId: string;
  startTime: string;
  updated: string;
  last_payment_time: string;
  next_billing_time: string;
  isTrial: boolean;
  __v: number;
}

export type IPayproPushbackResponse =
  | {
      data: null;
      result: false;
    }
  | {
      data: IPayproPushbackData;
      result: true;
    };

interface IOrderItemDetails {
  orderItemName: string;
  unitPriceValue: number;
  ordinalUnitPriceValue: number;
  quantity: number;
  id: string;
}

export interface IAditionalProduct {
  id: string;
  applicationId: string;
  productId: string;
  priceCurrencyCode: string;
  priceValue: number;
  orderItemDetails: IOrderItemDetails[];
  convertToReferenceCurrency: true;
  ordinalPriceValue: number;
  subtitle: string;
  title: string;
}

export interface IAditionalProductResponse extends IAditionalProduct {
  _id: string;
}

export interface IAditionalProductsResponse {
  data: { data: IAditionalProductResponse[] };
}
