import styled, { css } from 'styled-components';

import { TextButton } from 'components/TextButton/TextButton';

import { AuthVariant } from './types';
import { isOnboardingAuth, isPaywallAuth } from './utils';

interface IWithVariant {
  variant: AuthVariant;
}

const PaywallContainer = css`
  margin: 0 auto;
`;

export const Container = styled.div<IWithVariant>`
  padding: ${({ variant }) =>
    isOnboardingAuth(variant) ? '40px 0 20px' : '80px 20px 20px'};
  width: 100%;
  max-width: 524px;
  margin: auto;

  ${({ variant }) => isPaywallAuth(variant) && PaywallContainer};

  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3<IWithVariant>`
  margin-bottom: 48px;

  ${({ theme, variant }) =>
    isPaywallAuth(variant)
      ? theme.fonts.title4Black
      : theme.fonts.display3Regular};
  text-align: center;
`;
export const Subtitle = styled.h4`
  margin-bottom: 32px;

  ${({ theme }) => theme.fonts.headlineSemibold}
  text-align: center;
  color: ${({ theme }) => theme.colors.glyphFourth};
`;

export const BottomTextWrapper = styled.div`
  margin-top: auto;
`;

export const BottomText = styled.p`
  ${({ theme }) => theme.fonts.subheadRegular};

  text-align: center;
  color: ${({ theme }) => theme.colors.textAdditional};
`;

export const CountdownWrap = styled.div`
  margin: 32px auto;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.backgroundSeparatorsSurface};
`;

export const LockRow = styled.div`
  display: flex;

  margin-top: 24px;
`;

export const LockText = styled.p<{ center: boolean }>`
  margin-left: 16px;

  ${({ center }) => center && 'text-align: center'};

  ${({ theme }) => theme.fonts.captionRegular};

  color: ${({ theme }) => theme.colors.glyphFifth};
`;

export const SecondaryButton = styled(TextButton)`
  ${({ theme }) => theme.fonts.paragraphMedium};
`;
