const {
  REACT_APP_API_URL,
  REACT_APP_SENSES_MEDIA_API_URL,
  REACT_APP_AUTH_KEY,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_PAYPRO_SECRET_KEY,
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_FB_PIXEL_ID,
  REACT_APP_APPLICATION_ENV,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_BUNDLE_ID,
  REACT_APP_YA_METRIKA_ID,
  REACT_APP_VERSION,
  REACT_APP_SNAP_PIXEL_ID,
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_PREVIEW,
  REACT_APP_GTM_CONTAINER_ID,
  REACT_APP_TIKTOK_PIXEL_ID,
  REACT_APP_GA_ID,
  REACT_APP_PINTEREST_PIXEL_ID,
} = process.env;

const AppConfig = {
  API_URL: REACT_APP_API_URL ?? '',
  SENSES_MEDIA_API_URL: REACT_APP_SENSES_MEDIA_API_URL ?? '',
  AUTH_KEY: REACT_APP_AUTH_KEY,
  STRIPE_PUBLISHABLE_KEY: REACT_APP_STRIPE_PUBLISHABLE_KEY,
  PAYPAL_CLIENT_ID: REACT_APP_PAYPAL_CLIENT_ID,
  PAYPRO_SECRET_KEY: REACT_APP_PAYPRO_SECRET_KEY ?? '',
  AMPLITUDE_API_KEY: REACT_APP_AMPLITUDE_API_KEY ?? '',
  FB_PIXEL_ID: REACT_APP_FB_PIXEL_ID?.split(',') ?? [],
  SNAP_PIXEL_ID: REACT_APP_SNAP_PIXEL_ID?.split(',') ?? [],
  TIKTOK_PIXEL_ID: REACT_APP_TIKTOK_PIXEL_ID?.split(',') ?? [],
  PINTEREST_PIXEL_ID: REACT_APP_PINTEREST_PIXEL_ID ?? '',
  APPLICATION_ENV: REACT_APP_APPLICATION_ENV as 'sandbox' | 'prod',
  FIREBASE_API_KEY: REACT_APP_FIREBASE_API_KEY ?? '',
  FIREBASE_AUTH_DOMAIN: REACT_APP_FIREBASE_AUTH_DOMAIN ?? '',
  FIREBASE_PROJECT_ID: REACT_APP_FIREBASE_PROJECT_ID ?? '',
  FIREBASE_STORAGE_BUCKET: REACT_APP_FIREBASE_STORAGE_BUCKET ?? '',
  FIREBASE_MESSAGING_SENDER_ID: REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '',
  FIREBASE_APP_ID: REACT_APP_FIREBASE_APP_ID ?? '',
  FIREBASE_MEASUREMENT_ID: REACT_APP_FIREBASE_MEASUREMENT_ID ?? '',
  BUNDLE_ID: REACT_APP_BUNDLE_ID ?? '',
  YA_METRIKA_ID: parseInt(REACT_APP_YA_METRIKA_ID || '0', 10),
  APP_VERSION: REACT_APP_VERSION ?? '',
  GTM_AUTH: REACT_APP_GTM_AUTH ?? '',
  GTM_PREVIEW: REACT_APP_GTM_PREVIEW ?? '',
  GTM_CONTAINER_ID: REACT_APP_GTM_CONTAINER_ID ?? '',
  IS_DEV: REACT_APP_APPLICATION_ENV === 'sandbox',
  GA_ID: REACT_APP_GA_ID ?? '',
};
export { AppConfig };
