import { DATE_INTERVAL, IProductDescription } from 'types/types';

export function isPremium() {
  return window.location.host.includes('premium');
}

export function isRelations() {
  return window.location.host.includes('relations');
}

export function getPixelMarketingPrice(data: IProductDescription): number {
  const { interval, intervalCount, trial } = data;
  if (isPremium()) {
    return trial.amount / 100;
  }

  if (
    (interval === DATE_INTERVAL.WEEK && intervalCount === 1) ||
    (interval === DATE_INTERVAL.DAY && intervalCount === 7)
  ) {
    return 47;
  } else if (interval === DATE_INTERVAL.MONTH && intervalCount === 3) {
    return 73;
  } else {
    return 54;
  }
}
