import styled from 'styled-components';

import { PageHeader } from 'components/PageHeader/PageHeader';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media only screen and (hover: none) and (pointer: coarse) {
    min-height: 100dvh;
    height: -webkit-fill-available;
    overflow: scroll;
  }
`;

export const Header = styled(PageHeader)`
  position: relative;
`;

export const CloseButtonWrap = styled.button`
  display: flex;

  &:hover {
    opacity: 0.5;
  }
`;
