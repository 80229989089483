import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useMst } from 'stores/RootStore';

import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';
import { GA } from 'services/ga';

import { getCurrencySymbol, getDiscountPercentage } from 'utils/common';
import { getMMSSfromSeconds } from 'utils/formaters';

import { useRelativeNavigation } from 'hooks/useRelativeNavigation';
import { useTimer } from 'hooks/useTimer';

import { APP_ROUTES } from 'navigations/Routs';

import { PaymentTypes } from 'constants/common';

import { getPeriodTitleFromInterval } from 'pages/PaywallPage/PaywallTypes/PaywallPopup/utils';
import { getPricePerDayTitle } from 'pages/PaywallPage/utils/common';

import { LoadingState } from 'components/LoadingState/LoadingState';
import { PaymentPopup } from 'components/PaymentPopup/PaymentPopup';
import { Popup } from 'components/Popup';

import { DiscountPageStateless } from './components/DiscountPageStateless/DiscountPageStateless';

const initialTime = 10 * 60 * 1000;

export const DiscountPage = observer(() => {
  const { secondsLeft } = useTimer(initialTime);
  const { paymentStore } = useMst();
  const { push } = useRelativeNavigation();

  const isLoading = paymentStore.isLoading;

  const isPaypro = paymentStore.paymentType === PaymentTypes.PAYPRO;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const newProductData = paymentStore.sortedProducts?.find(
      ([id]) => id === paymentStore.productId,
    );

    if (!newProductData) {
      push(APP_ROUTES.PAY_WALL);
      return;
    }

    const [discountedProductId] = newProductData;

    paymentStore.onChangeProductId(discountedProductId);
  }, [paymentStore, isLoading]);

  useEffect(() => {
    if (!isPaypro) {
      push(APP_ROUTES.PAY_WALL);
    }
    Amplitude.logEvent(ANALYTICAL_EVENTS.DISCOUNT_PAGE);
  }, [isPaypro]);

  const handleBuyWithDiscountPress = () => {
    GA.event('begin_checkout');
    //@ts-ignore
    Popup.openContent(<PaymentPopup />);
  };

  if (!isPaypro) {
    return null;
  }

  const time = getMMSSfromSeconds(secondsLeft ?? 0);
  const discountedProductData = paymentStore.payproProductData!;
  const trial = discountedProductData?.trial;

  if (isLoading || !trial) {
    return <LoadingState />;
  }

  const currency = getCurrencySymbol(discountedProductData.currency);
  const priceBeforeDiscount = `${currency}${
    discountedProductData.amount / 100
  }`;
  const plan = getPeriodTitleFromInterval(trial);
  const pricePerDayBeforeDiscount = getPricePerDayTitle(discountedProductData);
  const pricePerDayAfterDiscount = getPricePerDayTitle(trial);

  const discountAmount = getDiscountPercentage(
    discountedProductData.amount,
    trial.amount,
  );

  const discountInfo = {
    plan,
    priceBeforeDiscount,
    pricePerDayAfterDiscount,
    pricePerDayBeforeDiscount,
  };

  const onClose = () => {
    push(APP_ROUTES.PAY_WALL);
  };

  return (
    <DiscountPageStateless
      discountAmount={discountAmount}
      discountInfo={discountInfo}
      onBuyWithDiscountPress={handleBuyWithDiscountPress}
      time={time}
      onClose={onClose}
    />
  );
});
